@import "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";
/*!
 * BootstrapVue Custom CSS (https://bootstrap-vue.js.org)
 */
@media (max-width: 575.98px) {
  .bv-d-xs-down-none {
    display: none !important; } }

@media (max-width: 767.98px) {
  .bv-d-sm-down-none {
    display: none !important; } }

@media (max-width: 991.98px) {
  .bv-d-md-down-none {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .bv-d-lg-down-none {
    display: none !important; } }

.bv-d-xl-down-none {
  display: none !important; }

.card-img-left {
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-img-right {
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px); }

.dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none !important; }

.dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret::before {
  display: none !important; }

.dropdown.b-dropdown .b-dropdown-form {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  width: 100%;
  clear: both;
  font-weight: 400; }

.dropdown.b-dropdown .b-dropdown-form:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important; }

.dropdown.b-dropdown .b-dropdown-form.disabled, .dropdown.b-dropdown .b-dropdown-form:disabled {
  outline: 0 !important;
  color: #6c757d;
  pointer-events: none; }

.b-dropdown-text {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  margin-bottom: 0;
  width: 100%;
  clear: both;
  font-weight: lighter; }

.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-left: 1.875rem; }

.custom-checkbox.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-checkbox .custom-control-label::before {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.3rem; }

.custom-checkbox.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-checkbox .custom-control-label::after {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background-size: 50% 50%; }

.custom-checkbox.b-custom-control-sm,
.input-group-sm .custom-checkbox {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 1.3125rem; }

.custom-checkbox.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-checkbox .custom-control-label::before {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.2rem; }

.custom-checkbox.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-checkbox .custom-control-label::after {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  background-size: 50% 50%; }

.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
  padding-left: 2.8125rem; }

.custom-switch.b-custom-control-lg .custom-control-label,
.input-group-lg .custom-switch .custom-control-label {
  font-size: 1.25rem;
  line-height: 1.5; }

.custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .custom-control-label::before {
  top: 0.3125rem;
  height: 1.25rem;
  left: -2.8125rem;
  width: 2.1875rem;
  border-radius: 0.625rem; }

.custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: calc( 0.3125rem + 2px);
  left: calc( -2.8125rem + 2px);
  width: calc( 1.25rem - 4px);
  height: calc( 1.25rem - 4px);
  border-radius: 0.625rem;
  background-size: 50% 50%; }

.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(0.9375rem);
  transform: translateX(0.9375rem); }

.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
  padding-left: 1.96875rem; }

.custom-switch.b-custom-control-sm .custom-control-label,
.input-group-sm .custom-switch .custom-control-label {
  font-size: 0.875rem;
  line-height: 1.5; }

.custom-switch.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-switch .custom-control-label::before {
  top: 0.21875rem;
  left: -1.96875rem;
  width: 1.53125rem;
  height: 0.875rem;
  border-radius: 0.4375rem; }

.custom-switch.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-label::after {
  top: calc( 0.21875rem + 2px);
  left: calc( -1.96875rem + 2px);
  width: calc( 0.875rem - 4px);
  height: calc( 0.875rem - 4px);
  border-radius: 0.4375rem;
  background-size: 50% 50%; }

.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(0.65625rem);
  transform: translateX(0.65625rem); }

.input-group > .input-group-prepend > .btn-group > .btn,
.input-group > .input-group-append:not(:last-child) > .btn-group > .btn,
.input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn-group > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn,
.input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.b-custom-control-lg.custom-file,
.b-custom-control-lg .custom-file-input,
.b-custom-control-lg .custom-file-label,
.input-group-lg.custom-file,
.input-group-lg .custom-file-input,
.input-group-lg .custom-file-label {
  font-size: 1.25rem;
  height: calc(1.5em + 1rem + 2px); }

.b-custom-control-lg .custom-file-label,
.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label,
.input-group-lg .custom-file-label:after {
  padding: 0.5rem 1rem;
  line-height: 1.5; }

.b-custom-control-lg .custom-file-label,
.input-group-lg .custom-file-label {
  border-radius: 0.3rem; }

.b-custom-control-lg .custom-file-label::after,
.input-group-lg .custom-file-label::after {
  font-size: inherit;
  height: calc( 1.5em + 1rem);
  border-radius: 0 0.3rem 0.3rem 0; }

.b-custom-control-sm.custom-file,
.b-custom-control-sm .custom-file-input,
.b-custom-control-sm .custom-file-label,
.input-group-sm.custom-file,
.input-group-sm .custom-file-input,
.input-group-sm .custom-file-label {
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 2px); }

.b-custom-control-sm .custom-file-label,
.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label,
.input-group-sm .custom-file-label:after {
  padding: 0.25rem 0.5rem;
  line-height: 1.5; }

.b-custom-control-sm .custom-file-label,
.input-group-sm .custom-file-label {
  border-radius: 0.2rem; }

.b-custom-control-sm .custom-file-label::after,
.input-group-sm .custom-file-label::after {
  font-size: inherit;
  height: calc( 1.5em + 0.5rem);
  border-radius: 0 0.2rem 0.2rem 0; }

.was-validated .form-control:invalid,
.was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-position: right calc(0.375em + 0.1875rem) center; }

input[type="color"].form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.125rem 0.25rem; }

input[type="color"].form-control.form-control-sm,
.input-group-sm input[type="color"].form-control {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.125rem 0.25rem; }

input[type="color"].form-control.form-control-lg,
.input-group-lg input[type="color"].form-control {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.125rem 0.25rem; }

input[type="color"].form-control:disabled {
  background-color: #adb5bd;
  opacity: 0.65; }

.input-group > .custom-range {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

.input-group > .custom-range + .form-control,
.input-group > .custom-range + .form-control-plaintext,
.input-group > .custom-range + .custom-select,
.input-group > .custom-range + .custom-range,
.input-group > .custom-range + .custom-file {
  margin-left: -1px; }

.input-group > .form-control + .custom-range,
.input-group > .form-control-plaintext + .custom-range,
.input-group > .custom-select + .custom-range,
.input-group > .custom-range + .custom-range,
.input-group > .custom-file + .custom-range {
  margin-left: -1px; }

.input-group > .custom-range:focus {
  z-index: 3; }

.input-group > .custom-range:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-range:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .custom-range {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .input-group > .custom-range {
    transition: none; } }

.input-group > .custom-range:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.input-group > .custom-range:disabled, .input-group > .custom-range[readonly] {
  background-color: #e9ecef; }

.input-group-lg > .custom-range {
  height: calc(1.5em + 1rem + 2px);
  padding: 0 1rem;
  border-radius: 0.3rem; }

.input-group-sm > .custom-range {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0 0.5rem;
  border-radius: 0.2rem; }

.was-validated .input-group .custom-range:valid, .input-group .custom-range.is-valid {
  border-color: #28a745; }

.was-validated .input-group .custom-range:valid:focus, .input-group .custom-range.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-range:valid:focus::-webkit-slider-thumb, .custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #9be7ac; }

.was-validated .custom-range:valid:focus::-moz-range-thumb, .custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #9be7ac; }

.was-validated .custom-range:valid:focus::-ms-thumb, .custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #9be7ac; }

.was-validated .custom-range:valid::-webkit-slider-thumb, .custom-range.is-valid::-webkit-slider-thumb {
  background-color: #28a745;
  background-image: none; }

.was-validated .custom-range:valid::-webkit-slider-thumb:active, .custom-range.is-valid::-webkit-slider-thumb:active {
  background-color: #9be7ac;
  background-image: none; }

.was-validated .custom-range:valid::-webkit-slider-runnable-track, .custom-range.is-valid::-webkit-slider-runnable-track {
  background-color: rgba(40, 167, 69, 0.35); }

.was-validated .custom-range:valid::-moz-range-thumb, .custom-range.is-valid::-moz-range-thumb {
  background-color: #28a745;
  background-image: none; }

.was-validated .custom-range:valid::-moz-range-thumb:active, .custom-range.is-valid::-moz-range-thumb:active {
  background-color: #9be7ac;
  background-image: none; }

.was-validated .custom-range:valid::-moz-range-track, .custom-range.is-valid::-moz-range-track {
  background: rgba(40, 167, 69, 0.35); }

.was-validated .custom-range:valid ~ .valid-feedback,
.was-validated .custom-range:valid ~ .valid-tooltip, .custom-range.is-valid ~ .valid-feedback,
.custom-range.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-range:valid::-ms-thumb, .custom-range.is-valid::-ms-thumb {
  background-color: #28a745;
  background-image: none; }

.was-validated .custom-range:valid::-ms-thumb:active, .custom-range.is-valid::-ms-thumb:active {
  background-color: #9be7ac;
  background-image: none; }

.was-validated .custom-range:valid::-ms-track-lower, .custom-range.is-valid::-ms-track-lower {
  background: rgba(40, 167, 69, 0.35); }

.was-validated .custom-range:valid::-ms-track-upper, .custom-range.is-valid::-ms-track-upper {
  background: rgba(40, 167, 69, 0.35); }

.was-validated .input-group .custom-range:invalid, .input-group .custom-range.is-invalid {
  border-color: #dc3545; }

.was-validated .input-group .custom-range:invalid:focus, .input-group .custom-range.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-range:invalid:focus::-webkit-slider-thumb, .custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #f6cdd1; }

.was-validated .custom-range:invalid:focus::-moz-range-thumb, .custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #f6cdd1; }

.was-validated .custom-range:invalid:focus::-ms-thumb, .custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #f6cdd1; }

.was-validated .custom-range:invalid::-webkit-slider-thumb, .custom-range.is-invalid::-webkit-slider-thumb {
  background-color: #dc3545;
  background-image: none; }

.was-validated .custom-range:invalid::-webkit-slider-thumb:active, .custom-range.is-invalid::-webkit-slider-thumb:active {
  background-color: #f6cdd1;
  background-image: none; }

.was-validated .custom-range:invalid::-webkit-slider-runnable-track, .custom-range.is-invalid::-webkit-slider-runnable-track {
  background-color: rgba(220, 53, 69, 0.35); }

.was-validated .custom-range:invalid::-moz-range-thumb, .custom-range.is-invalid::-moz-range-thumb {
  background-color: #dc3545;
  background-image: none; }

.was-validated .custom-range:invalid::-moz-range-thumb:active, .custom-range.is-invalid::-moz-range-thumb:active {
  background-color: #f6cdd1;
  background-image: none; }

.was-validated .custom-range:invalid::-moz-range-track, .custom-range.is-invalid::-moz-range-track {
  background: rgba(220, 53, 69, 0.35); }

.was-validated .custom-range:invalid ~ .invalid-feedback,
.was-validated .custom-range:invalid ~ .invalid-tooltip, .custom-range.is-invalid ~ .invalid-feedback,
.custom-range.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-range:invalid::-ms-thumb, .custom-range.is-invalid::-ms-thumb {
  background-color: #dc3545;
  background-image: none; }

.was-validated .custom-range:invalid::-ms-thumb:active, .custom-range.is-invalid::-ms-thumb:active {
  background-color: #f6cdd1;
  background-image: none; }

.was-validated .custom-range:invalid::-ms-track-lower, .custom-range.is-invalid::-ms-track-lower {
  background: rgba(220, 53, 69, 0.35); }

.was-validated .custom-range:invalid::-ms-track-upper, .custom-range.is-invalid::-ms-track-upper {
  background: rgba(220, 53, 69, 0.35); }

.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-left: 1.875rem; }

.custom-radio.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-radio .custom-control-label::before {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%; }

.custom-radio.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-radio .custom-control-label::after {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background: no-repeat 50% / 50% 50%; }

.custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 1.3125rem; }

.custom-radio.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-radio .custom-control-label::before {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%; }

.custom-radio.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-radio .custom-control-label::after {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  background: no-repeat 50% / 50% 50%; }

.modal-backdrop {
  opacity: 0.5; }

.popover.b-popover {
  display: block;
  opacity: 1; }

.popover.b-popover.fade:not(.show) {
  opacity: 0; }

.popover.b-popover.show {
  opacity: 1; }

.b-popover-primary.popover {
  background-color: #cce5ff;
  border-color: #b8daff; }

.b-popover-primary.bs-popover-top > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #b8daff; }

.b-popover-primary.bs-popover-top > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #cce5ff; }

.b-popover-primary.bs-popover-right > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #b8daff; }

.b-popover-primary.bs-popover-right > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #cce5ff; }

.b-popover-primary.bs-popover-bottom > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #b8daff; }

.b-popover-primary.bs-popover-bottom > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #bdddff; }

.b-popover-primary.bs-popover-bottom .popover-header::before, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #bdddff; }

.b-popover-primary.bs-popover-left > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #b8daff; }

.b-popover-primary.bs-popover-left > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #cce5ff; }

.b-popover-primary .popover-header {
  color: #212529;
  background-color: #bdddff;
  border-bottom-color: #a3d0ff; }

.b-popover-primary .popover-body {
  color: #004085; }

.b-popover-secondary.popover {
  background-color: #e2e3e5;
  border-color: #d6d8db; }

.b-popover-secondary.bs-popover-top > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #d6d8db; }

.b-popover-secondary.bs-popover-top > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #e2e3e5; }

.b-popover-secondary.bs-popover-right > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #d6d8db; }

.b-popover-secondary.bs-popover-right > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #e2e3e5; }

.b-popover-secondary.bs-popover-bottom > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #d6d8db; }

.b-popover-secondary.bs-popover-bottom > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #dadbde; }

.b-popover-secondary.bs-popover-bottom .popover-header::before, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #dadbde; }

.b-popover-secondary.bs-popover-left > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #d6d8db; }

.b-popover-secondary.bs-popover-left > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #e2e3e5; }

.b-popover-secondary .popover-header {
  color: #212529;
  background-color: #dadbde;
  border-bottom-color: #ccced2; }

.b-popover-secondary .popover-body {
  color: #383d41; }

.b-popover-success.popover {
  background-color: #d4edda;
  border-color: #c3e6cb; }

.b-popover-success.bs-popover-top > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #c3e6cb; }

.b-popover-success.bs-popover-top > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #d4edda; }

.b-popover-success.bs-popover-right > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #c3e6cb; }

.b-popover-success.bs-popover-right > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #d4edda; }

.b-popover-success.bs-popover-bottom > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #c3e6cb; }

.b-popover-success.bs-popover-bottom > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #c9e8d1; }

.b-popover-success.bs-popover-bottom .popover-header::before, .b-popover-success.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #c9e8d1; }

.b-popover-success.bs-popover-left > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #c3e6cb; }

.b-popover-success.bs-popover-left > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #d4edda; }

.b-popover-success .popover-header {
  color: #212529;
  background-color: #c9e8d1;
  border-bottom-color: #b7e1c1; }

.b-popover-success .popover-body {
  color: #155724; }

.b-popover-info.popover {
  background-color: #d1ecf1;
  border-color: #bee5eb; }

.b-popover-info.bs-popover-top > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #bee5eb; }

.b-popover-info.bs-popover-top > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #d1ecf1; }

.b-popover-info.bs-popover-right > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #bee5eb; }

.b-popover-info.bs-popover-right > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #d1ecf1; }

.b-popover-info.bs-popover-bottom > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #bee5eb; }

.b-popover-info.bs-popover-bottom > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #c5e7ed; }

.b-popover-info.bs-popover-bottom .popover-header::before, .b-popover-info.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #c5e7ed; }

.b-popover-info.bs-popover-left > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #bee5eb; }

.b-popover-info.bs-popover-left > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #d1ecf1; }

.b-popover-info .popover-header {
  color: #212529;
  background-color: #c5e7ed;
  border-bottom-color: #b2dfe7; }

.b-popover-info .popover-body {
  color: #0c5460; }

.b-popover-warning.popover {
  background-color: #fff3cd;
  border-color: #ffeeba; }

.b-popover-warning.bs-popover-top > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #ffeeba; }

.b-popover-warning.bs-popover-top > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #fff3cd; }

.b-popover-warning.bs-popover-right > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #ffeeba; }

.b-popover-warning.bs-popover-right > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #fff3cd; }

.b-popover-warning.bs-popover-bottom > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #ffeeba; }

.b-popover-warning.bs-popover-bottom > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #ffefbe; }

.b-popover-warning.bs-popover-bottom .popover-header::before, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #ffefbe; }

.b-popover-warning.bs-popover-left > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #ffeeba; }

.b-popover-warning.bs-popover-left > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #fff3cd; }

.b-popover-warning .popover-header {
  color: #212529;
  background-color: #ffefbe;
  border-bottom-color: #ffe9a4; }

.b-popover-warning .popover-body {
  color: #856404; }

.b-popover-danger.popover {
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.b-popover-danger.bs-popover-top > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #f5c6cb; }

.b-popover-danger.bs-popover-top > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #f8d7da; }

.b-popover-danger.bs-popover-right > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #f5c6cb; }

.b-popover-danger.bs-popover-right > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #f8d7da; }

.b-popover-danger.bs-popover-bottom > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #f5c6cb; }

.b-popover-danger.bs-popover-bottom > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #f6cace; }

.b-popover-danger.bs-popover-bottom .popover-header::before, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #f6cace; }

.b-popover-danger.bs-popover-left > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #f5c6cb; }

.b-popover-danger.bs-popover-left > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #f8d7da; }

.b-popover-danger .popover-header {
  color: #212529;
  background-color: #f6cace;
  border-bottom-color: #f2b4ba; }

.b-popover-danger .popover-body {
  color: #721c24; }

.b-popover-light.popover {
  background-color: #fefefe;
  border-color: #fdfdfe; }

.b-popover-light.bs-popover-top > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #fdfdfe; }

.b-popover-light.bs-popover-top > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #fefefe; }

.b-popover-light.bs-popover-right > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #fdfdfe; }

.b-popover-light.bs-popover-right > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #fefefe; }

.b-popover-light.bs-popover-bottom > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #fdfdfe; }

.b-popover-light.bs-popover-bottom > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #f6f6f6; }

.b-popover-light.bs-popover-bottom .popover-header::before, .b-popover-light.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #f6f6f6; }

.b-popover-light.bs-popover-left > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #fdfdfe; }

.b-popover-light.bs-popover-left > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #fefefe; }

.b-popover-light .popover-header {
  color: #212529;
  background-color: #f6f6f6;
  border-bottom-color: #eaeaea; }

.b-popover-light .popover-body {
  color: #818182; }

.b-popover-dark.popover {
  background-color: #d6d8d9;
  border-color: #c6c8ca; }

.b-popover-dark.bs-popover-top > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #c6c8ca; }

.b-popover-dark.bs-popover-top > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #d6d8d9; }

.b-popover-dark.bs-popover-right > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #c6c8ca; }

.b-popover-dark.bs-popover-right > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #d6d8d9; }

.b-popover-dark.bs-popover-bottom > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #c6c8ca; }

.b-popover-dark.bs-popover-bottom > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #ced0d2; }

.b-popover-dark.bs-popover-bottom .popover-header::before, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #ced0d2; }

.b-popover-dark.bs-popover-left > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #c6c8ca; }

.b-popover-dark.bs-popover-left > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #d6d8d9; }

.b-popover-dark .popover-header {
  color: #212529;
  background-color: #ced0d2;
  border-bottom-color: #c1c4c5; }

.b-popover-dark .popover-body {
  color: #1b1e21; }

.table.b-table.b-table-fixed {
  table-layout: fixed; }

.table.b-table.b-table-no-border-collapse {
  border-collapse: separate;
  border-spacing: 0; }

.table.b-table[aria-busy="true"] {
  opacity: 0.55; }

.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important; }

.table.b-table > caption {
  caption-side: bottom; }

.table.b-table.b-table-caption-top > caption {
  caption-side: top !important; }

.b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 1rem; }

.b-table-sticky-header > .table,
.table-responsive > .table,
[class*="table-responsive-"] > .table {
  margin-bottom: 0; }

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 300px; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2; }
  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > thead > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > tbody > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    position: -webkit-sticky;
    position: sticky;
    left: 0; }
  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > thead > tr > .b-table-sticky-column {
    z-index: 5; }
  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > tbody > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    z-index: 2; }
  .table.b-table > thead > tr > .table-b-table-default,
  .table.b-table > tbody > tr > .table-b-table-default,
  .table.b-table > tfoot > tr > .table-b-table-default {
    color: #212529;
    background-color: #fff; }
  .table.b-table.table-dark > thead > tr > .bg-b-table-default,
  .table.b-table.table-dark > tbody > tr > .bg-b-table-default,
  .table.b-table.table-dark > tfoot > tr > .bg-b-table-default {
    color: #fff;
    background-color: #343a40; }
  .table.b-table.table-striped > tbody > tr:nth-of-type(odd) > .table-b-table-default {
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    background-repeat: no-repeat; }
  .table.b-table.table-striped.table-dark > tbody > tr:nth-of-type(odd) > .bg-b-table-default {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    background-repeat: no-repeat; }
  .table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
    color: #212529;
    background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
    background-repeat: no-repeat; }
  .table.b-table.table-hover.table-dark > tbody > tr:hover > .bg-b-table-default {
    color: #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
    background-repeat: no-repeat; } }

.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
  cursor: pointer;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 0.65em 1em; }

.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.75rem / 2) center;
  padding-right: calc(0.75rem + 0.65em); }

.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.75rem / 2) center;
  padding-left: calc(0.75rem + 0.65em); }

.table.b-table > thead > tr > [aria-sort="none"],
.table.b-table > tfoot > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table > thead > tr > [aria-sort="ascending"],
.table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table > thead > tr > [aria-sort="descending"],
.table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table.table-dark > thead > tr > [aria-sort="none"],
.table.b-table.table-dark > tfoot > tr > [aria-sort="none"],
.table.b-table > .thead-dark > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table.table-dark > thead > tr > [aria-sort="ascending"],
.table.b-table.table-dark > tfoot > tr > [aria-sort="ascending"],
.table.b-table > .thead-dark > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table.table-dark > thead > tr > [aria-sort="descending"],
.table.b-table.table-dark > tfoot > tr > [aria-sort="descending"],
.table.b-table > .thead-dark > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table > thead > tr > .table-dark[aria-sort="none"],
.table.b-table > tfoot > tr > .table-dark[aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table > thead > tr > .table-dark[aria-sort="ascending"],
.table.b-table > tfoot > tr > .table-dark[aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table > thead > tr > .table-dark[aria-sort="descending"],
.table.b-table > tfoot > tr > .table-dark[aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"); }

.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.3rem / 2) center;
  padding-right: calc(0.3rem + 0.65em); }

.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.3rem / 2) center;
  padding-left: calc(0.3rem + 0.65em); }

.table.b-table.b-table-selectable > tbody > tr {
  cursor: pointer; }

.table.b-table.b-table-selectable.b-table-selecting.b-table-select-range > tbody > tr {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

@media (max-width: 575.98px) {
  .table.b-table.b-table-stacked-sm {
    display: block;
    width: 100%; }
  .table.b-table.b-table-stacked-sm > caption,
  .table.b-table.b-table-stacked-sm > tbody,
  .table.b-table.b-table-stacked-sm > tbody > tr,
  .table.b-table.b-table-stacked-sm > tbody > tr > td,
  .table.b-table.b-table-stacked-sm > tbody > tr > th {
    display: block; }
  .table.b-table.b-table-stacked-sm > thead,
  .table.b-table.b-table-stacked-sm > tfoot {
    display: none; }
  .table.b-table.b-table-stacked-sm > thead > tr.b-table-top-row,
  .table.b-table.b-table-stacked-sm > thead > tr.b-table-bottom-row,
  .table.b-table.b-table-stacked-sm > tfoot > tr.b-table-top-row,
  .table.b-table.b-table-stacked-sm > tfoot > tr.b-table-bottom-row {
    display: none; }
  .table.b-table.b-table-stacked-sm > caption {
    caption-side: top !important; }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0; }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: ""; }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0; }
  .table.b-table.b-table-stacked-sm > tbody > tr.top-row, .table.b-table.b-table-stacked-sm > tbody > tr.bottom-row {
    display: none; }
  .table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
    border-top-width: 3px; }
  .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + th {
    border-top-width: 3px; } }

@media (max-width: 767.98px) {
  .table.b-table.b-table-stacked-md {
    display: block;
    width: 100%; }
  .table.b-table.b-table-stacked-md > caption,
  .table.b-table.b-table-stacked-md > tbody,
  .table.b-table.b-table-stacked-md > tbody > tr,
  .table.b-table.b-table-stacked-md > tbody > tr > td,
  .table.b-table.b-table-stacked-md > tbody > tr > th {
    display: block; }
  .table.b-table.b-table-stacked-md > thead,
  .table.b-table.b-table-stacked-md > tfoot {
    display: none; }
  .table.b-table.b-table-stacked-md > thead > tr.b-table-top-row,
  .table.b-table.b-table-stacked-md > thead > tr.b-table-bottom-row,
  .table.b-table.b-table-stacked-md > tfoot > tr.b-table-top-row,
  .table.b-table.b-table-stacked-md > tfoot > tr.b-table-bottom-row {
    display: none; }
  .table.b-table.b-table-stacked-md > caption {
    caption-side: top !important; }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0; }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: ""; }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0; }
  .table.b-table.b-table-stacked-md > tbody > tr.top-row, .table.b-table.b-table-stacked-md > tbody > tr.bottom-row {
    display: none; }
  .table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 3px; }
  .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + th {
    border-top-width: 3px; } }

@media (max-width: 991.98px) {
  .table.b-table.b-table-stacked-lg {
    display: block;
    width: 100%; }
  .table.b-table.b-table-stacked-lg > caption,
  .table.b-table.b-table-stacked-lg > tbody,
  .table.b-table.b-table-stacked-lg > tbody > tr,
  .table.b-table.b-table-stacked-lg > tbody > tr > td,
  .table.b-table.b-table-stacked-lg > tbody > tr > th {
    display: block; }
  .table.b-table.b-table-stacked-lg > thead,
  .table.b-table.b-table-stacked-lg > tfoot {
    display: none; }
  .table.b-table.b-table-stacked-lg > thead > tr.b-table-top-row,
  .table.b-table.b-table-stacked-lg > thead > tr.b-table-bottom-row,
  .table.b-table.b-table-stacked-lg > tfoot > tr.b-table-top-row,
  .table.b-table.b-table-stacked-lg > tfoot > tr.b-table-bottom-row {
    display: none; }
  .table.b-table.b-table-stacked-lg > caption {
    caption-side: top !important; }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0; }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: ""; }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0; }
  .table.b-table.b-table-stacked-lg > tbody > tr.top-row, .table.b-table.b-table-stacked-lg > tbody > tr.bottom-row {
    display: none; }
  .table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
    border-top-width: 3px; }
  .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + th {
    border-top-width: 3px; } }

@media (max-width: 1199.98px) {
  .table.b-table.b-table-stacked-xl {
    display: block;
    width: 100%; }
  .table.b-table.b-table-stacked-xl > caption,
  .table.b-table.b-table-stacked-xl > tbody,
  .table.b-table.b-table-stacked-xl > tbody > tr,
  .table.b-table.b-table-stacked-xl > tbody > tr > td,
  .table.b-table.b-table-stacked-xl > tbody > tr > th {
    display: block; }
  .table.b-table.b-table-stacked-xl > thead,
  .table.b-table.b-table-stacked-xl > tfoot {
    display: none; }
  .table.b-table.b-table-stacked-xl > thead > tr.b-table-top-row,
  .table.b-table.b-table-stacked-xl > thead > tr.b-table-bottom-row,
  .table.b-table.b-table-stacked-xl > tfoot > tr.b-table-top-row,
  .table.b-table.b-table-stacked-xl > tfoot > tr.b-table-bottom-row {
    display: none; }
  .table.b-table.b-table-stacked-xl > caption {
    caption-side: top !important; }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0; }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: ""; }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0; }
  .table.b-table.b-table-stacked-xl > tbody > tr.top-row, .table.b-table.b-table-stacked-xl > tbody > tr.bottom-row {
    display: none; }
  .table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
    border-top-width: 3px; }
  .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + th {
    border-top-width: 3px; } }

.table.b-table.b-table-stacked {
  display: block;
  width: 100%; }

.table.b-table.b-table-stacked > caption,
.table.b-table.b-table-stacked > tbody,
.table.b-table.b-table-stacked > tbody > tr,
.table.b-table.b-table-stacked > tbody > tr > td,
.table.b-table.b-table-stacked > tbody > tr > th {
  display: block; }

.table.b-table.b-table-stacked > thead,
.table.b-table.b-table-stacked > tfoot {
  display: none; }

.table.b-table.b-table-stacked > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked > tfoot > tr.b-table-bottom-row {
  display: none; }

.table.b-table.b-table-stacked > caption {
  caption-side: top !important; }

.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  content: attr(data-label);
  width: 40%;
  float: left;
  text-align: right;
  overflow-wrap: break-word;
  font-weight: bold;
  font-style: normal;
  padding: 0 calc(1rem / 2) 0 0;
  margin: 0; }

.table.b-table.b-table-stacked > tbody > tr > [data-label]::after {
  display: block;
  clear: both;
  content: ""; }

.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  display: inline-block;
  width: calc(100% - 40%);
  padding: 0 0 0 calc(1rem / 2);
  margin: 0; }

.table.b-table.b-table-stacked > tbody > tr.top-row, .table.b-table.b-table-stacked > tbody > tr.bottom-row {
  display: none; }

.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 3px; }

.table.b-table.b-table-stacked > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + th {
  border-top-width: 3px; }

.b-toast {
  display: block;
  position: relative;
  max-width: 350px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-clip: padding-box;
  z-index: 1;
  border-radius: 0.25rem; }

.b-toast .toast {
  background-color: rgba(255, 255, 255, 0.85); }

.b-toast:not(:last-child) {
  margin-bottom: 0.75rem; }

.b-toast.b-toast-solid .toast {
  background-color: white; }

.b-toast .toast {
  opacity: 1; }

.b-toast .toast.fade:not(.show) {
  opacity: 0; }

.b-toast .toast .toast-body {
  display: block; }

.b-toast-primary .toast {
  background-color: rgba(230, 242, 255, 0.85);
  border-color: rgba(184, 218, 255, 0.85);
  color: #004085; }

.b-toast-primary .toast .toast-header {
  color: #004085;
  background-color: rgba(204, 229, 255, 0.85);
  border-bottom-color: rgba(184, 218, 255, 0.85); }

.b-toast-primary.b-toast-solid .toast {
  background-color: #e6f2ff; }

.b-toast-secondary .toast {
  background-color: rgba(239, 240, 241, 0.85);
  border-color: rgba(214, 216, 219, 0.85);
  color: #383d41; }

.b-toast-secondary .toast .toast-header {
  color: #383d41;
  background-color: rgba(226, 227, 229, 0.85);
  border-bottom-color: rgba(214, 216, 219, 0.85); }

.b-toast-secondary.b-toast-solid .toast {
  background-color: #eff0f1; }

.b-toast-success .toast {
  background-color: rgba(230, 245, 233, 0.85);
  border-color: rgba(195, 230, 203, 0.85);
  color: #155724; }

.b-toast-success .toast .toast-header {
  color: #155724;
  background-color: rgba(212, 237, 218, 0.85);
  border-bottom-color: rgba(195, 230, 203, 0.85); }

.b-toast-success.b-toast-solid .toast {
  background-color: #e6f5e9; }

.b-toast-info .toast {
  background-color: rgba(229, 244, 247, 0.85);
  border-color: rgba(190, 229, 235, 0.85);
  color: #0c5460; }

.b-toast-info .toast .toast-header {
  color: #0c5460;
  background-color: rgba(209, 236, 241, 0.85);
  border-bottom-color: rgba(190, 229, 235, 0.85); }

.b-toast-info.b-toast-solid .toast {
  background-color: #e5f4f7; }

.b-toast-warning .toast {
  background-color: rgba(255, 249, 231, 0.85);
  border-color: rgba(255, 238, 186, 0.85);
  color: #856404; }

.b-toast-warning .toast .toast-header {
  color: #856404;
  background-color: rgba(255, 243, 205, 0.85);
  border-bottom-color: rgba(255, 238, 186, 0.85); }

.b-toast-warning.b-toast-solid .toast {
  background-color: #fff9e7; }

.b-toast-danger .toast {
  background-color: rgba(252, 237, 238, 0.85);
  border-color: rgba(245, 198, 203, 0.85);
  color: #721c24; }

.b-toast-danger .toast .toast-header {
  color: #721c24;
  background-color: rgba(248, 215, 218, 0.85);
  border-bottom-color: rgba(245, 198, 203, 0.85); }

.b-toast-danger.b-toast-solid .toast {
  background-color: #fcedee; }

.b-toast-light .toast {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(253, 253, 254, 0.85);
  color: #818182; }

.b-toast-light .toast .toast-header {
  color: #818182;
  background-color: rgba(254, 254, 254, 0.85);
  border-bottom-color: rgba(253, 253, 254, 0.85); }

.b-toast-light.b-toast-solid .toast {
  background-color: white; }

.b-toast-dark .toast {
  background-color: rgba(227, 229, 229, 0.85);
  border-color: rgba(198, 200, 202, 0.85);
  color: #1b1e21; }

.b-toast-dark .toast .toast-header {
  color: #1b1e21;
  background-color: rgba(214, 216, 217, 0.85);
  border-bottom-color: rgba(198, 200, 202, 0.85); }

.b-toast-dark.b-toast-solid .toast {
  background-color: #e3e5e5; }

.b-toaster {
  z-index: 1100; }

.b-toaster .b-toaster-slot {
  position: relative;
  display: block; }

.b-toaster .b-toaster-slot:empty {
  display: none !important; }

.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full, .b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible; }

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  position: absolute;
  max-width: 350px;
  width: 100%;
  /* IE11 fix */
  left: 0;
  right: 0;
  padding: 0;
  margin: 0; }

.b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  width: 100%;
  max-width: 100%; }

.b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-top-full .b-toaster-slot .toast, .b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-bottom-full .b-toaster-slot .toast {
  width: 100%;
  max-width: 100%; }

.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full {
  top: 0; }

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot {
  top: 0.5rem; }

.b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  bottom: 0; }

.b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  bottom: 0.5rem; }

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-left: auto; }

.b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-right: auto; }

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-move, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move {
  transition: -webkit-transform 0.175s;
  transition: transform 0.175s;
  transition: transform 0.175s, -webkit-transform 0.175s; }

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade {
  transition-delay: 0.175s; }

.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active {
  position: absolute;
  transition-delay: 0.175s; }

.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade {
  transition-delay: 0s; }

.tooltip.b-tooltip {
  display: block;
  opacity: 0.9; }

.tooltip.b-tooltip.fade:not(.show) {
  opacity: 0; }

.tooltip.b-tooltip.show {
  opacity: 0.9; }

.tooltip.b-tooltip-primary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #007bff; }

.tooltip.b-tooltip-primary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #007bff; }

.tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #007bff; }

.tooltip.b-tooltip-primary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #007bff; }

.tooltip.b-tooltip-primary .tooltip-inner {
  color: #fff;
  background-color: #007bff; }

.tooltip.b-tooltip-secondary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #6c757d; }

.tooltip.b-tooltip-secondary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #6c757d; }

.tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #6c757d; }

.tooltip.b-tooltip-secondary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #6c757d; }

.tooltip.b-tooltip-secondary .tooltip-inner {
  color: #fff;
  background-color: #6c757d; }

.tooltip.b-tooltip-success.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #28a745; }

.tooltip.b-tooltip-success.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #28a745; }

.tooltip.b-tooltip-success.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #28a745; }

.tooltip.b-tooltip-success.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #28a745; }

.tooltip.b-tooltip-success .tooltip-inner {
  color: #fff;
  background-color: #28a745; }

.tooltip.b-tooltip-info.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #17a2b8; }

.tooltip.b-tooltip-info.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #17a2b8; }

.tooltip.b-tooltip-info.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #17a2b8; }

.tooltip.b-tooltip-info.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #17a2b8; }

.tooltip.b-tooltip-info .tooltip-inner {
  color: #fff;
  background-color: #17a2b8; }

.tooltip.b-tooltip-warning.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #ffc107; }

.tooltip.b-tooltip-warning.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #ffc107; }

.tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #ffc107; }

.tooltip.b-tooltip-warning.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #ffc107; }

.tooltip.b-tooltip-warning .tooltip-inner {
  color: #212529;
  background-color: #ffc107; }

.tooltip.b-tooltip-danger.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #dc3545; }

.tooltip.b-tooltip-danger.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #dc3545; }

.tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #dc3545; }

.tooltip.b-tooltip-danger.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #dc3545; }

.tooltip.b-tooltip-danger .tooltip-inner {
  color: #fff;
  background-color: #dc3545; }

.tooltip.b-tooltip-light.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #f8f9fa; }

.tooltip.b-tooltip-light.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #f8f9fa; }

.tooltip.b-tooltip-light.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #f8f9fa; }

.tooltip.b-tooltip-light.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #f8f9fa; }

.tooltip.b-tooltip-light .tooltip-inner {
  color: #212529;
  background-color: #f8f9fa; }

.tooltip.b-tooltip-dark.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #343a40; }

.tooltip.b-tooltip-dark.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #343a40; }

.tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #343a40; }

.tooltip.b-tooltip-dark.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #343a40; }

.tooltip.b-tooltip-dark .tooltip-inner {
  color: #fff;
  background-color: #343a40; }

/*# sourceMappingURL=bootstrap-vue.css.map */
body {
  font-weight: 400;
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  background-color: #f6f7fa; }

a:hover {
  text-decoration: none; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #444649; }

h6.coupon {
  padding: 5px 0; }

span.total-price {
  font-weight: 900; }

/*Checkbox ------------------*/
.custom-control {
  padding-left: 30px; }

.custom-control-label::before {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  left: -30px;
  background-color: #f6f7fa;
  border: 1px solid #c5c7cb; }

.custom-control-label::after {
  top: .25rem;
  left: -1.95rem;
  width: 1.2rem;
  height: 1.1rem; }

.custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #007bff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: none;
  box-shadow: none; }

/* Navbar --------*/
.navbar-light {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 7.76px 0.24px rgba(210, 211, 215, 0.2);
  box-shadow: 0px 0px 7.76px 0.24px rgba(210, 211, 215, 0.2);
  padding: .1rem 1rem;
  z-index: 0; }

.nav-item .cart-icon {
  position: relative;
  background-color: #dfebfc;
  height: 42px;
  width: 42px;
  display: block;
  border-radius: 50%;
  line-height: 42px;
  text-align: center;
  color: #277aec;
  font-size: 23px;
  margin-right: 35px; }

.cart-number {
  position: absolute;
  height: 18px;
  width: 18px;
  background-color: #28d228;
  font-size: 11px;
  top: -4px;
  line-height: 18px;
  color: #fff;
  border-radius: 50%;
  right: -5px; }

.country-dropdown .btn {
  background-color: #f6f7fa;
  border-color: #e5e7eb;
  border-radius: 3px;
  font-size: 16px;
  color: #8c8e91 !important;
  padding: .375rem 1.3rem !important; }

.navbar-brand span {
  text-transform: uppercase;
  font-weight: 700;
  color: #1c1368;
  font-size: 23px;
  display: block;
  margin: 17px 0; }

@media (max-width: 991px) {
  .navbar-brand img {
    height: 60px; } }

.navbar-expand-lg .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row; }

.navbar-expand-lg .navbar-collapse {
  /* display: -ms-flexbox !important; */
  /*display: flex !important;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;*/ }

/*.collapse:not(.show) {
	display: block;
}
*/
.navbar-light .navbar-toggler {
  border: 0; }

.navbar-light .navbar-toggler-icon {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f0c9"; }

/* Navbar progressbar -----*/
#progressbar {
  overflow: hidden;
  counter-reset: step;
  width: 100%;
  padding: 0; }

#progressbar li {
  list-style-type: none;
  color: #444649;
  font-size: 15px;
  width: 33.33%;
  float: left;
  position: relative;
  text-align: center; }

#progressbar li::before {
  content: counter(step);
  counter-increment: step;
  width: 26px;
  height: 25px;
  display: block;
  font-size: 14px;
  color: #595959;
  background: #d0d2d8;
  border-radius: 50%;
  margin: 0 auto 5px auto;
  line-height: 26px; }

/*progressbar connectors*/
#progressbar li::after {
  content: '';
  width: 100%;
  height: 4px;
  background: #e1e4e9;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; }

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none; }

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active,
#progressbar li.active {
  color: #277aec; }

#progressbar li.active::before,
#progressbar li.active::after {
  background: #277aec;
  color: white; }

/* Product grid --------------- */
.product-grid-content {
  padding-top: 60px; }

.product-grid {
  background-color: #fff;
  margin-bottom: 50px;
  border: 1px solid #eff2f5;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  border-radius: 3px; }

.product-grid:hover {
  -webkit-box-shadow: 4px 7px 16px 0px #dcdee3;
  box-shadow: 4px 7px 16px 0px #dcdee3; }

.product-grid .product-img {
  padding: 26px;
  min-height: 280px;
  vertical-align: top;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.product-grid .product-info {
  padding: 20px;
  border-top: 1px solid #eff2f5;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s; }

.product-info .title a {
  font-size: 22px;
  color: #444649;
  font-weight: 500;
  display: block; }

.product-info .title a:hover {
  color: #277aec; }

.product-info .subtitle {
  font-size: 16px;
  color: #b2b5b8;
  margin-bottom: 15px; }

.price {
  font-size: 26px;
  font-weight: 700;
  color: #444649; }

@media (min-width: 575px) and (max-width: 767px) {
  .product-info .title a {
    font-size: 17px; }
  .product-grid .btn {
    font-size: 11px;
    padding: .420rem 1rem; } }

/* Product list --------------- */
.product-list-content {
  padding-top: 60px; }

.product-list {
  padding: 10px;
  border: 1px solid #e6e9ed;
  position: relative;
  width: 100%;
  float: left;
  display: inline-block;
  -webkit-box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25);
  box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25);
  background-color: #fff;
  margin-bottom: 22px; }

.product-list:hover {
  -webkit-box-shadow: 4px 6.928px 15px 0px #e2e3e8;
  box-shadow: 4px 6.928px 15px 0px #e2e3e8; }

.product-list .table-view {
  float: none;
  display: table;
  /* margin-right: -15px; */
  /* margin-left: -15px; */
  width: 100%; }

.cell-view {
  display: table-cell;
  vertical-align: middle; }

.radius-top {
  position: relative;
  overflow: hidden; }

.product-list .product-info {
  padding: 20px 40px;
  border-right: 1px solid #ddd; }

.product-list .product-buy {
  padding: 20px 40px; }

.product-list .price {
  margin-bottom: 10px; }

.product-list .btn {
  padding: .530rem 1.46rem; }

@media (max-width: 767px) {
  .product-list {
    padding: 15px; }
  .cell-view {
    display: block;
    width: 100%; }
  .radius-top img {
    width: 100%; }
  .product-list .product-info {
    width: 100%;
    border-right: 0;
    padding: 20px 0 0; }
  .product-list .product-buy {
    padding: 0;
    width: 100%; } }

/* Product list two --------------- */
.product-list.product-list-two {
  margin-bottom: 35px; }

.product-list.product-list-two .product-info {
  width: 100%;
  border-right: 0; }

.product-list.product-list-two .product-buy {
  width: 100%; }

@media (min-width: 768px) {
  .product-list.product-list-two .product-info,
  .product-list.product-list-two .product-buy {
    padding: 0 0 0 30px; } }

/* Delivery page  --------------*/
.delivery-content {
  padding: 40px 0 40px; }

.message {
  text-align: center;
  margin-bottom: 60px; }

.message .icon {
  margin-bottom: 20px; }

.message h2 {
  font-size: 32px; }

.message p {
  font-size: 20px;
  color: #7b7e81; }

.order-details, .product-panel,
.customer-panel {
  border-radius: 3px;
  background-color: white;
  border: 1px solid #e6e9ed;
  -webkit-box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25);
  box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25);
  margin: 0 auto 38px; }

.order-details .header,
.product-panel .header,
.customer-panel .header {
  /*border: 1px solid rgb( 230, 233, 237 );*/
  background-image: -webkit-linear-gradient(90deg, #e5e6ec 0%, #f8f9fb 100%);
  background-image: -ms-linear-gradient(90deg, #e5e6ec 0%, #f8f9fb 100%);
  padding: 14px 30px; }

.order-details .header h3,
.product-panel .header h3,
.customer-panel .header h3 {
  font-size: 20px;
  color: #2f3032; }

.product-panel-body,
.customer-panel-body {
  padding: 30px; }

.order-info div {
  font-size: 16px;
  color: #2f3032;
  font-weight: 500; }

.order-info {
  margin-bottom: 15px; }

.order-total-info ul {
  margin-bottom: 25px; }

.order-total-info ul li {
  font-size: 16px;
  color: #7b7e81;
  margin-bottom: 12px; }

.order-total-info ul li strong {
  color: #2f3032;
  width: 100px;
  display: inline-block; }

.btn-download {
  font-size: 15px;
  border-radius: 2px;
  color: #6a6d71;
  background-color: #f0f1f6;
  border: 1px solid #e4e5ea;
  padding: 7px 21px; }

.btn-download i {
  margin-right: 5px; }

.order {
  border-bottom: 1px solid #e6e8ed;
  padding-bottom: 15px;
  margin-bottom: 25px; }

.product-panel-body .row {
  padding-bottom: 20px;
  border-bottom: 1px solid #e7e9ed;
  margin: 0 0 20px; }

.product-panel-body .row:nth-child(3),
.product-panel-body .row:nth-child(4) {
  padding-bottom: 50px;
  padding-top: 30px; }

.product-panel-body .row:last-child {
  border-bottom: 0;
  margin: 0;
  padding: 0; }

.product-panel-body .row [class*="col-"] {
  padding: 0; }

.product-panel-body .item_name h4 {
  font-size: 17px; }

.product-panel-body .item_name p {
  font-size: 16px;
  color: #a1a3a6; }

.product-panel-body .btn {
  font-size: 16px;
  border-radius: 3px;
  padding: 8px 19px; }

.dropdown-menu {
  border-radius: 2px;
  border: 0;
  -webkit-box-shadow: 2.5px 4.33px 12px 0px #e1e3ea;
  box-shadow: 2.5px 4.33px 12px 0px #e1e3ea; }

.dropdown-item {
  font-size: 16px;
  color: #7b7e81;
  padding: .40rem 1.5rem; }

.dropdown-item:focus, .dropdown-item:hover,
.dropdown-item.active, .dropdown-item:active {
  color: #277aec;
  background-color: transparent; }

.h-15 {
  height: 15px; }

.serial_number {
  font-size: 20px;
  color: #444649;
  background-color: #f6f7fa;
  border-radius: 3px;
  border: 1px solid #edeef2;
  padding: 4px 24px;
  margin: 10px 8px 0 0;
  width: 100%; }

.copy {
  border-radius: 3px;
  background-color: #277aec;
  padding: 9px 11px;
  text-align: center;
  cursor: pointer;
  border: 0; }

.customer-info {
  margin-bottom: 20px; }

.customer-info h5 {
  font-size: 18px;
  color: #2f3032;
  margin-bottom: 10px; }

.customer-info p {
  font-size: 16px;
  color: #7b7e81; }

/* Modal -------*/
.modal-content {
  border: 0; }

.modal-header {
  padding: 16px 30px; }

.modal-primary .modal-header {
  background-color: #267aec;
  border-color: #267aec; }

.modal-header h5 {
  font-size: 24px;
  color: white; }

.modal-body {
  padding: 30px 20px 30px 35px; }

.des {
  padding-right: 30px; }

.des h4 {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 15px; }

.des p {
  font-size: 16px;
  color: #737477;
  margin-bottom: 20px; }

.modal-footer-btn .btn {
  border-radius: 3px;
  font-size: 18px;
  color: #999b9f;
  border-color: transparent;
  background-color: transparent;
  padding: 7px 20px; }

.modal-footer-btn .btn i {
  margin-left: 5px; }

.modal-footer-btn .btn.btn-primary {
  color: #fff;
  border: none;
  background-color: #267aec; }

.slimScrollBar {
  opacity: 1 !important; }

@media (max-width: 767px) {
  .product-panel-body, .customer-panel-body {
    padding: 15px; }
  .order-total-info ul li strong {
    width: 70px; }
  .product-panel-body .item_image {
    margin-right: 12px; }
  .product-panel-body .item_image img {
    height: 60px; }
  .product-panel-body .item_name p {
    font-size: 15px;
    margin-bottom: 15px; }
  .product-panel-body .btn {
    font-size: 15px;
    padding: 5px 15px; }
  .serial_number {
    font-size: 12px;
    padding: 10px 9px;
    margin-right: 8px; }
  .modal-body {
    padding: 30px 18px 30px 20px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .order-details, .product-panel, .customer-panel {
    width: 748px; } }

/* Pagination --------------*/
.pagination {
  margin: 10px 0 90px; }

.page-link {
  border: 1px solid #D3D5DB;
  margin: 3px;
  color: #686E71;
  border-radius: 3px !important;
  padding: .5rem .84rem;
  background-color: transparent; }

.page-item span {
  margin-top: 26px;
  display: block;
  line-height: 1;
  letter-spacing: 4px;
  color: #abadb1; }

.page-item.active .page-link {
  background-color: #596069;
  border-color: #596069; }

@media (max-width: 575px) {
  .page-link {
    padding: .5rem .75rem; }
  .page-item span {
    display: none; } }

/* Shopping Cart  --------------*/
.table {
  margin: 0; }

.table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: middle; }

.table td, .table th {
  border-bottom: 1px solid #dee2e6; }

@media screen and (max-width: 600px) {
  table#cart tbody td .form-control {
    width: 20%;
    display: inline !important; }
  .actions .btn {
    width: 36%;
    margin: 1.5em 0; }
  .actions .btn-info {
    float: left; }
  .actions .btn-danger {
    float: right; }
  table#cart thead {
    display: none; }
  table#cart tbody td {
    display: block;
    padding: .6rem;
    min-width: 320px; }
  table#cart tbody tr td:first-child {
    background: #333;
    color: #fff; }
  table#cart tbody td:before {
    content: attr(data-th);
    font-weight: bold;
    display: inline-block;
    width: 8rem; }
  table#cart tfoot td {
    display: block; }
  table#cart tfoot td .btn {
    display: block; } }

.cart-content {
  padding: 67px 0 90px; }

.cart-content .panel-body {
  padding-bottom: 13px; }

.cart-panel .row {
  margin-right: 0;
  margin-left: 0;
  border-bottom: 1px solid #e7e9ed;
  padding-bottom: 10px;
  margin-bottom: 10px; }

/*.cart-panel .row:last-child {
	border: 0;
	margin: 0 !important;
	padding-bottom: 0 !important;
}*/
.cart-panel [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.cart-panel {
  border: 1px solid #e6e9ed;
  background-color: white;
  -webkit-box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25);
  box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25);
  border-radius: 2px;
  background-image: -webkit-linear-gradient(90deg, #f6f7fa 0%, white 100%);
  background-image: -ms-linear-gradient(90deg, #f6f7fa 0%, white 100%);
  margin-bottom: 33px; }

.panel-body {
  padding: 30px; }

.panel-head {
  background-image: -webkit-linear-gradient(90deg, #e5e6ec 0%, #f8f9fb 100%);
  background-image: -ms-linear-gradient(90deg, #e5e6ec 0%, #f8f9fb 100%);
  padding: 17px; }

.item_title, .quantity_title, .u_price_title, .action_title {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px; }

.item_image {
  margin-right: 30px; }

.item_image img {
  border: none; }

.item_name {
  width: 100%; }

.item_name h3 {
  font-size: 24px; }

.item_name h4 {
  font-size: 20px; }

.item_name p {
  font-size: 18px;
  color: #999b9f;
  margin: 0; }

.action {
  text-align: center; }

.action .btn {
  color: #8c8f92;
  background-color: transparent; }

.action .btn:hover {
  background-color: #ffe9e5;
  color: #ff2a00; }

.tooltip-inner {
  font-size: 13px;
  font-weight: 500;
  background-color: #596069;
  border-radius: 2px; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #596069; }

.u_price {
  font-size: 20px;
  font-weight: 500; }

/*Spinner*/
.spinner {
  width: 48px;
  height: 40px; }

.spinner input {
  text-align: right;
  border-color: #cfd1d5; }

.input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell; }

.input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 10px 13px;
  margin-left: -1px;
  position: relative;
  border-radius: 0;
  border: 1px solid #cfd1d5;
  background-color: #f6f7fa; }

.input-group-btn-vertical > .btn:first-child {
  border-top-right-radius: 4px; }

.input-group-btn-vertical > .btn:last-child {
  margin-top: -2px;
  border-bottom-right-radius: 4px; }

.input-group-btn-vertical i {
  position: absolute;
  top: 2px;
  left: 7px;
  color: #afb2b8; }

/* Cupon --------*/
.cart-panel .cart-total.row {
  padding-bottom: 30px;
  padding-top: 20px; }

.cupon .input-group-append .btn {
  background-color: #39ce39;
  color: #fff;
  padding: .375rem 1.7rem; }

.total-price-info {
  text-align: right; }

.total-price-info h3 {
  font-size: 18px;
  font-weight: 500; }

.total-price-info p {
  font-size: 15px;
  margin: 0;
  color: #999b9f; }

/*Form -----------*/
.cart-panel .cart-info-form.row {
  padding-bottom: 0;
  padding-top: 17px;
  border: 0;
  margin: 0; }

.cart-info-form .form-group {
  margin-right: 15px; }

.cart-info-form [class*="col-"]:last-child .form-group {
  margin-right: 0; }

.quantity .form-control {
  height: 42px; }

label {
  font-size: 16px;
  color: #444649;
  font-weight: 500;
  margin-bottom: .3rem; }

.btn-row .btn {
  font-size: 18px;
  padding: 12px 24px 10px; }

.btn-row .btn.btn-deafult {
  color: #444649;
  background-color: #fff;
  border: 1px solid #dcdde0; }

.btn-row .btn.btn-primary {
  color: #fff;
  background-color: #277aec;
  border: none; }

.btn-row .btn.btn-deafult i {
  margin-right: 5px; }

.btn-row .btn.btn-primary i {
  margin-left: 5px; }

@media (max-width: 767px) {
  .panel-body {
    padding: 15px; }
  .panel-head {
    display: none; }
  .item_image {
    margin-right: 15px; }
  .item_name h4 {
    font-size: 18px; }
  .item_name p {
    font-size: 16px; }
  .quantity::before, .u_price::before, .action::before {
    content: attr(data-price);
    font-weight: 500;
    display: inline-block;
    width: 8rem; }
  .action {
    text-align: left; }
  .btn-row div:first-child .btn {
    margin-bottom: 10px; } }

@media (min-width: 768px) {
  .btn-row div:last-child {
    text-align: right; } }

/* Checkout --------------*/
.checkout-content {
  padding: 40px 0 60px; }

.checkout-content h4 {
  padding-top: 10px; }

.checkout-form, .check-order {
  margin-bottom: 38px;
  border: 1px solid #e6e9ed;
  background-color: #fff;
  -webkit-box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25);
  box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25); }

.form-header,
.order-head {
  padding: 15px 30px;
  /*border: 1px solid rgb( 230, 233, 237 );*/
  background-image: -webkit-linear-gradient(90deg, #e5e6ec 0%, #f8f9fb 100%);
  background-image: -ms-linear-gradient(90deg, #e5e6ec 0%, #f8f9fb 100%); }

.form-header h5,
.order-head h5 {
  font-size: 22px;
  color: #2f3032;
  font-weight: 500; }

.form-body {
  padding: 30px 60px 30px 30px; }

@media (max-width: 991px) {
  .form-body {
    padding: 20px; } }

/* Form control ------*/
.form-group p {
  color: #999b9f;
  font-size: 14px; }

.form-group label span {
  /*color: #ff2a00;*/ }

.form-text {
  color: #999b9f;
  font-size: 13px; }

.invalid-feedback {
  font-size: 13px;
  color: #ff2a00; }

.form-control {
  height: 40px;
  border-color: #d5d7db;
  border-radius: 3px;
  font-size: 16px;
  color: #7a7d81; }

.form-control:hover {
  border-color: #277aec; }

.form-control:focus {
  -webkit-box-shadow: 0 0 0 2px #277aec;
  box-shadow: 0 0 0 2px #277aec; }

.form-control::-webkit-input-placeholder {
  color: #b1b1b1;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #b1b1b1;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #b1b1b1;
  opacity: 1; }

.form-control::-ms-input-placeholder {
  color: #b1b1b1;
  opacity: 1; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff2a00; }

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #ff2a00;
  -webkit-box-shadow: 0 0 0 2px #ff2a00;
  box-shadow: 0 0 0 2px #ff2a00; }

textarea.form-control {
  height: auto; }

.form-control.form-control-lg {
  height: 47px;
  font-size: 18px; }

/* Ui selection ----*/
.ui.selection.dropdown {
  min-width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 2px;
  min-height: 42px;
  font-size: 16px;
  color: #7a7d81; }

.ui.selection.dropdown:hover {
  border-color: #277aec; }

.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown:hover .menu {
  border-color: #277aec; }

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: #b1b1b1; }

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  padding: 1.03em; }

.ui.dropdown > .dropdown.icon::before {
  content: " ";
  width: 9px;
  height: 9px;
  border-right: 2px solid #b4b6bc;
  border-bottom: 2px solid #b4b6bc;
  position: absolute;
  right: 14px;
  top: 14px;
  transform: rotate(45deg);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1000000; }

.ui.active.selection.dropdown > .dropdown.icon::before {
  transform: rotate(225deg);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.ui.selection.dropdown .menu > .item {
  font-size: 16px;
  color: #7a7d81; }

.ui.dropdown .menu .active.item {
  font-weight: 500;
  color: #277aec; }

/* Check order -----*/
.check-order {
  -webkit-box-shadow: none;
  box-shadow: none; }

.order-head .edit {
  font-size: 16px;
  color: #277aec;
  text-decoration: underline;
  display: inline-block;
  margin-left: 10px; }

.order-body {
  padding: 30px; }

.product-name h5 {
  font-size: 17px; }

.product-cat {
  font-size: 16px;
  color: #999b9f; }

.Price-amount {
  font-size: 18px;
  font-weight: 500;
  color: #444649; }

.check-order .cupon {
  padding: 0 30px 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e6e9ec; }

.order-footer {
  position: relative;
  padding: 0 30px 20px; }

.have_coupon {
  font-size: 18px;
  color: #277aec;
  text-decoration: underline;
  top: 10px;
  position: absolute;
  left: 20px; }

.product-total {
  text-align: right; }

.order-footer .total-price-info h4 {
  font-size: 18px; }

.order-footer .total-price-info h4 .total-price {
  font-size: 26px; }

.order-footer .total-price-info p {
  font-size: 14px; }

@media (max-width: 991px) {
  .order-body {
    padding: 20px; } }

/* Card content -----*/
.card-select ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

/*.card-select .selected {border:2px solid green}*/
.payment-item {
  margin-bottom: 10px; }

.payment-item:last-child {
  margin-bottom: 0; }

.card-select .label {
  display: block;
  cursor: pointer;
  padding: 20px 15px;
  border-radius: 3px;
  margin-bottom: 0;
  border: 1px solid #eceff3;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-box-shadow: 2.5px 4.33px 10px 0px #edeff2;
  box-shadow: 2.5px 4.33px 10px 0px #edeff2; }

.card-select .label.active {
  border: 1px solid #b9d3f6; }

.card-select .label a {
  text-decoration: underline; }

.card-select .label span {
  font-size: 18px;
  color: #444649;
  margin-left: 10px; }

.card-select .label img {
  float: right; }

.card-wrapper {
  margin: 30px 0; }

.secure-logo a {
  display: inline-block;
  margin-right: 25px; }

.input-img {
  position: relative; }

.form-card-img {
  position: absolute;
  top: 9px;
  right: -42px; }

.card-des {
  font-size: 17px;
  position: relative;
  border-radius: 2px;
  background-color: #f3f3f3;
  color: #515151;
  padding: 1em;
  margin-top: 15px !important; }

.card-des::before {
  content: '';
  display: block;
  border: 10px solid #f3f3f3;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -3px;
  left: 0;
  margin: -1em 0 0 2em; }

@media (max-width: 575px) {
  .jp-card-container {
    width: 100% !important;
    height: 160px !important; }
  .jp-card {
    min-width: 100% !important; }
  .jp-card .jp-card-front .jp-card-lower .jp-card-number {
    margin-bottom: 8px !important;
    font-size: 21px !important; }
  .jp-card .jp-card-front .jp-card-lower {
    bottom: 22px !important; } }

/* Bootstrap 4 text input with search icon */
.has-search {
  position: relative; }

.has-search .form-control {
  padding-right: 57px; }

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  right: 8px;
  top: 5px; }

.question {
  display: block;
  position: absolute;
  right: -27px;
  top: 8px;
  color: #b7bbc0;
  font-size: 21px; }

.content-footer .btn {
  border-radius: 4px;
  margin-bottom: 22px;
  font-size: 20px;
  padding: .9rem .75rem; }

@media (max-width: 575px) {
  .card-select label img {
    float: right;
    height: 10px;
    margin-top: 8px; } }

@media (max-width: 767px) {
  .form-card-img {
    top: 15px;
    right: -18px;
    height: 12px; }
  .question {
    right: -18px;
    top: 10px;
    font-size: 15px; } }

/* Recovery content --------------*/
.recovery_content,
.active_content {
  padding: 80px 0; }

.recovery_box,
.active_box {
  padding: 40px 31px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #f0f1f5;
  -webkit-box-shadow: 0px 2px 4px 0px #e6e7ed;
  box-shadow: 0px 2px 4px 0px #e6e7ed; }

.recovery_box h3,
.active_box h3 {
  font-size: 32px;
  margin-bottom: 10px; }

.recovery_box p,
.active_box p {
  font-size: 18px;
  color: #797a7e; }

.recovery_box .btn,
.active_box .btn {
  height: 49px;
  border-radius: 2px;
  font-size: 18px;
  color: white;
  background-color: #277aec; }

.recovery_text h3,
.active_box h3 {
  font-size: 22px;
  margin-bottom: 20px; }

.active_box h3 {
  font-size: 32px;
  margin-bottom: 30px; }

.active_box h4 {
  font-size: 24px;
  line-height: 1.333;
  margin-bottom: 28px;
  font-weight: 400; }

.active_box .request {
  margin-top: 30px;
  font-size: 16px;
  color: #444649; }

.active_box .request a {
  color: #28d228;
  text-decoration: underline; }

.recovery_text p,
.active_text p {
  font-size: 15px;
  color: #797a7e;
  line-height: 1.467;
  margin-bottom: 20px; }

.alert {
  border: 0;
  font-size: 17px;
  border-radius: 3px;
  margin-bottom: 35px;
  padding: 15px 30px; }

.alert i {
  margin-right: 15px; }

.alert-success {
  color: #278f21;
  background-color: rgba(49, 186, 31, 0.302); }

.alert-danger {
  color: #e31742;
  background-color: rgba(255, 0, 0, 0.149); }

@media (max-width: 767px) {
  .recovery_box,
  .active_box {
    padding: 30px 20px;
    margin-bottom: 30px; }
  .recovery_box h3,
  .active_box h3 {
    font-size: 28px; } }

@media (min-width: 1200px) {
  .recovery_box, .active_box {
    width: 520px; }
  .recovery_text, .active_text {
    margin-left: 35px;
    margin-right: 35px; } }

/* Active code input --------------*/
/* My purchase content --------------*/
.purchase_content {
  padding: 60px 0 78px; }

.purchase-table h3 {
  font-size: 26px;
  margin-bottom: 35px;
  color: #2f3032; }

.purchase-table .table-responsive {
  -webkit-box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25);
  box-shadow: 2px 3px 6px 0px rgba(210, 211, 215, 0.25); }

.purchase-table .table {
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #e6e9ed; }

.purchase-table .table .thead-dark th {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #444649;
  text-transform: uppercase;
  padding: 17px 20px;
  border: 1px solid #e6e9ed;
  background-image: -webkit-linear-gradient(90deg, #e5e6ec 0%, #f8f9fb 100%);
  background-image: -ms-linear-gradient(90deg, #e5e6ec 0%, #f8f9fb 100%); }

.purchase-table .table > tbody > tr > td,
.purchase-table .table > tfoot > tr > td {
  padding: 20px; }

.purchase-table .item_image {
  margin-right: 20px; }

.purchase-table .item_name h4 {
  font-size: 18px;
  margin-bottom: 3px; }

.purchase-table .order-id {
  font-size: 15px;
  color: #444649; }

.purchase-table .order-id span {
  color: #39ce39; }

.purchase-table time,
.purchase-table .priec {
  font-size: 16px;
  color: #444649; }

.purchase-table .badge {
  font-size: 15px;
  border-radius: 2px;
  font-weight: 400;
  padding: .7em .9em; }

.purchase-table .badge-light {
  color: #5e5e5e;
  background-color: #e5e5e5; }

.purchase-table .badge-primary {
  color: #5a646a;
  background-color: #c8d8e1; }

.purchase-table .badge-warning {
  color: #7f755f;
  background-color: #f7dda7; }

.purchase-table .badge-success {
  color: #658265;
  background-color: #c6e1c6; }

.purchase-table .btn {
  border-radius: 2px;
  font-size: 15px;
  padding: .445rem .90rem; }

.purchase-table .btn:first-child {
  margin-right: 8px; }

.purchase-table .btn-primary {
  background-color: #277aec;
  border: none; }

.purchase-table .btn-light {
  color: #6a6d71;
  border-color: #e4e5ea;
  background-color: #f0f1f6; }

.purchase-table .btn i {
  margin-right: 3px; }

/* Pending Content --------------*/
.pending-box {
  margin: 80px auto;
  border: 1px solid #f0f1f5;
  border-radius: 3px;
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px #e6e7ed;
  box-shadow: 0px 2px 4px 0px #e6e7ed;
  padding: 30px; }

.pending-icon {
  margin-bottom: 30px; }

.pending-box h3 {
  font-size: 32px;
  margin-bottom: 15px; }

.pending-box p {
  font-size: 18px;
  color: #737477;
  margin: 0; }

@media (min-width: 768px) {
  .pending-box {
    width: 540px; } }

@media (min-width: 1200px) {
  .pending-box {
    padding: 50px; } }

/* Privacy policy content --------------*/
.content_header {
  color: #fff;
  background-color: #277aec;
  padding: 46px 0; }

.content_header h2 {
  font-size: 40px;
  margin-bottom: 15px;
  color: #fff; }

.content_header p {
  font-size: 18px;
  margin: 0;
  color: #dfecfe; }

.content-box {
  padding: 60px 50px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #e6e9ed;
  margin: 40px 0 90px; }

.content-box h4 {
  font-size: 26px;
  margin-bottom: 20px; }

.content-box p {
  font-size: 18px;
  margin-bottom: 40px;
  color: #737477;
  line-height: 1.556; }

.content-box p a {
  color: #3a99e3;
  text-decoration: underline; }

.user-text-box {
  padding: 30px 25px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #e6e9ed;
  margin: 0 0 20px; }

.user-text-box-bottom {
  padding: 30px 25px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #e6e9ed;
  margin: 20px 0; }

@media (max-width: 767px) {
  .content-box {
    padding: 25px; } }

/* Suspended content --------------*/
.suspended_box {
  border-radius: 3px;
  text-align: center;
  background-color: white;
  border: 1px solid #f0f1f5;
  -webkit-box-shadow: 0px 2px 4px 0px #e6e7ed;
  box-shadow: 0px 2px 4px 0px #e6e7ed;
  margin: 50px 15px;
  padding: 50px 20px; }

.suspended_box .icon {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  color: #ff2f2f;
  line-height: 90px;
  margin: 0 auto 30px;
  font-size: 40px;
  background-color: rgba(255, 47, 47, 0.122); }

.suspended_box h3 {
  font-size: 32px;
  margin-bottom: 20px; }

.suspended_box p,
.suspended_box .read {
  font-size: 18px;
  color: #797a7e; }

.suspended_box p {
  margin-bottom: 40px; }

.suspended_box .read a {
  color: #28d228;
  text-decoration: underline; }

@media (min-width: 768px) {
  .suspended_box {
    width: 640px;
    padding: 50px 40px;
    margin: 78px auto 79px; } }

/* Product details ---------*/
.product_details {
  margin: 50px 0; }

.product-info .product-title {
  font-size: 36px;
  margin-bottom: 10px; }

.product-info .meta-sku {
  font-size: 16px;
  margin-bottom: 35px;
  color: #28d228; }

.product-info .product-text {
  font-size: 18px;
  margin: 20px 0 40px;
  color: #7b7e81; }

.product-info .product-price {
  font-size: 54px;
  margin-bottom: 40px;
  color: #444649; }

.product-info .cart-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px; }

.product-info .cart-row .number-spinner {
  margin-right: 17px; }

.product-info .cart-row .form-control {
  height: 50px;
  width: 50px;
  border-color: #dfe0e4;
  text-align: center; }

.product-info .cart-row .form-control::-webkit-input-placeholder {
  color: #28d228;
  opacity: 1; }

.product-info .cart-row .form-control::-moz-placeholder {
  color: #28d228;
  opacity: 1; }

.product-info .cart-row .form-control:-ms-input-placeholder {
  color: #28d228;
  opacity: 1; }

.product-info .cart-row .form-control::-ms-input-placeholder {
  color: #28d228;
  opacity: 1; }

.product-info .cart-row .form-control::placeholder {
  color: #28d228;
  opacity: 1; }

.product-info .cart-row .cart-btn {
  border-radius: 2px;
  border: 0;
  background-color: #277aec;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  padding: 14px 53px 15px;
  cursor: pointer; }

.product-info .social-share {
  padding: 0;
  margin: 0;
  list-style-type: none; }

.product-info .social-share li {
  font-size: 13px;
  display: inline-block;
  color: #7b7e81;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase; }

.product-info .social-share li a {
  font-size: 15px;
  color: #7b7e81;
  display: block;
  margin-right: 5px; }

.product-info .social-share li a:hover {
  color: #277aec; }

.text-details {
  margin: 40px 0 0 20px; }

.text-details h3 {
  font-size: 24px;
  margin-bottom: 25px; }

.text-details p {
  color: #7b7e81;
  font-size: 16px;
  line-height: 1.625;
  margin-bottom: 30px; }

@media (min-width: 768px) and (max-width: 1199px) {
  .product-info .product-title {
    font-size: 28px; }
  .product-info .product-text {
    font-size: 16px;
    margin-bottom: 10px; }
  .product-info .product-price {
    font-size: 36px;
    margin-bottom: 15px; }
  .product-info .cart-row .form-control {
    height: 41px;
    width: 41px; }
  .product-info .cart-row .cart-btn {
    padding: 10px 25px; }
  .product-info .cart-row {
    margin-bottom: 20px; } }

/* Purchase id -------------*/
.purchase_id {
  text-align: center;
  border-width: 1px solid #f0f1f5;
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 4px 0px #e6e7ed;
  box-shadow: 0px 2px 4px 0px #e6e7ed;
  padding: 50px 20px;
  margin: 50px 15px; }

.purchase_id .icon {
  margin-bottom: 30px; }

.purchase_id h3 {
  font-size: 32px;
  margin-bottom: 20px; }

.purchase_id .order_id {
  font-size: 18px;
  color: #999b9f;
  background: #f6f7fa;
  border: 1px solid #edeef2;
  border-radius: 2px;
  padding: 6px 15px;
  margin-bottom: 30px;
  display: inline-block; }

.purchase_id .order_id span {
  color: #444649; }

.purchase_id p {
  font-size: 18px;
  color: #6e7175; }

/* Product slider --------- */
.slick-slider {
  margin-bottom: 20px; }

.slick-slide img {
  width: 100%; }

.product-slider-nav .slick-slide {
  margin: 10px;
  cursor: pointer;
  border: 3px solid transparent; }

.product-slider-nav .slick-slide.slick-current {
  border: 3px solid #28d228; }

.slick-prev::before, .slick-next::before {
  font-family: inherit;
  color: #000; }

.slick-next::before {
  /*content: '&#8594';*/ }

@media (min-width: 768px) {
  .purchase_id {
    width: 740px;
    margin: 80px auto;
    padding: 60px; } }

@media (min-width: 1200px) {
  .slick-prev {
    left: -35px; }
  .slick-next {
    right: -35px; } }

/* Cross sell --------------*/
.cross-sell-content {
  padding: 74px 0 80px; }

.cross-sell-content .title {
  font-size: 30px;
  text-align: center;
  margin-bottom: 58px; }

.product_box {
  border: 1px solid #eff2f5;
  border-radius: 2px;
  background-color: white;
  margin: 0 auto;
  text-align: center; }

.product_item {
  padding: 20px; }

.product_text {
  padding: 20px;
  margin: -1px;
  border: 1px solid #e6e9ed; }

.product_text h5 {
  font-size: 22px;
  margin-bottom: 15px; }

.product_text .price {
  font-size: 26px;
  color: #444649;
  margin-bottom: 20px; }

.cart_btn .btn {
  font-size: 16px;
  border-radius: 2px;
  padding: 7px 16px; }

.cart_btn .btn:first-child {
  margin-right: 7px; }

.cart_btn .btn-light {
  color: #fff;
  border: 1px solid #277aec;
  background-color: #277aec; }

.cart_btn .btn-light {
  color: #6a6d71;
  border: 1px solid #e4e5ea;
  background-color: #f0f1f6; }

@media (min-width: 768px) {
  .product_box {
    width: 358px; }
  .product_text {
    padding: 30px; }
  .cart_btn .btn {
    padding: 7px 25px; }
  .cross-sell-content .title {
    font-size: 32px; } }

/* Upsell product --------------*/
.upsell-content .product_box {
  width: 100%; }

.upsell-content .cart_btn .btn:first-child {
  margin-right: 0; }

@media (max-width: 767px) {
  .upsell-content .product_box {
    margin-bottom: 30px; } }

/* 404 page --------------*/
.page-error-content {
  padding: 70px 0; }

.error-element {
  text-align: center; }

.error-element h3 {
  font-size: 56px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #ff1b5b; }

.error-element img {
  margin-bottom: 70px; }

.error-element p {
  font-size: 30px;
  color: #6c6d6f;
  margin-bottom: 30px; }

.error-element span {
  font-size: 300px;
  line-height: 1;
  font-weight: bold; }

.error-element .btn {
  border-radius: 3px;
  background-color: #277aec;
  font-size: 17px;
  padding: 9px 25px; }

.page-error-content.error-content2 {
  padding: 200px 0; }

.error-content2 .error-element h3 {
  margin-bottom: 50px; }

.error-content2 .error-text {
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: bold;
  line-height: 1; }

@media (min-width: 768px) {
  .page-error-content {
    padding: 100px 0; }
  .error-element h3 {
    font-size: 80px;
    margin-bottom: 50px; }
  .error-element img {
    margin-bottom: 100px; } }

/* Footer --------------*/
footer {
  padding: 40px 20px;
  text-align: center;
  background-color: #393c40; }

.copyright,
.footer-link a {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.6); }

.footer-link {
  color: #94999f;
  margin-bottom: 55px; }

.footer-link a {
  margin: 0 10px; }

.footer-link a:hover {
  color: #94999f; }

.footer-logo span {
  font-size: 18px;
  color: #969ea6;
  margin-right: 8px; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  color: #7a7d81;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  -webkit-box-shadow: 0 0 0 2px #277aec;
  box-shadow: 0 0 0 2px #277aec; }

.StripeElement--invalid {
  border-color: #ff2a00; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.btn-primary {
  border: none !important; }

/*# sourceMappingURL=cart-v4.css.map */